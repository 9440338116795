<template>
  <div>
    <h4 class="is-size-4 has-padding-bottom">Check your email for your secret code 🕵️‍♀️</h4>
    <div class="has-margin-top">
      <el-form @submit.prevent.native="onSubmit" label-position="left" label-width="140px">
        <el-form-item label="Username">
          <el-input type="text" v-model="username"></el-input>
        </el-form-item>
        <el-form-item label="Confirmation Code">
          <el-input type="text" v-model="code"></el-input>
        </el-form-item>
        <el-button
          v-loading="pending"
          native-type="submit"
          type="primary"
          :style="{ width: '70%' }"
        >Confirm Sign Up</el-button>
      </el-form>
    </div>
    <div class="has-margin-top">
      Lost your code?
      <el-button type="text" @click="resendCode">Resend Code</el-button>
    </div>
    <div class="has-margin-top">
      Have an account?
      <el-button type="text" @click="showSignIn">Back to Sign In</el-button>
    </div>
  </div>
</template>

<script>
import { AmplifyEventBus } from 'aws-amplify-vue'
import Amplify from 'aws-amplify'

export default {
  name: 'ConfirmSignUp',
  props: ['localUsername'],
  data() {
    return {
      username: this.localUsername,
      code: '',
      pending: false,
    }
  },
  methods: {
    async onSubmit(evt) {
      evt.preventDefault()
      try {
        this.pending = true
        await this.$Amplify.Auth.confirmSignUp(this.username, this.code)
        AmplifyEventBus.$emit('authState', 'signIn')
        this.pending = false
      } catch (e) {
        console.log('error', e)
        this.$message.error(`Problem resending code: ${e}`)
        this.pending = false
      }
    },
    showSignIn() {
      AmplifyEventBus.$emit('authState', 'signIn')
    },
    async resendCode() {
      try {
        await this.$Amplify.Auth.resendSignUp(this.username)
        this.$message('Code resent. Check your email 🙂')
      } catch (e) {
        console.log(e)
        this.$message.error(`Problem resending code: ${e}`)
      }
    },
  },
}
</script>
