<template>
  <div>
    <h4 class="is-size-4 has-padding-bottom">Let's get you back into your account</h4>
    <div v-if="!sent" class="has-margin-top">
      <el-form @submit.prevent.native="sendCode" label-position="left" label-width="80px">
        <el-form-item label="Username">
          <el-input type="text" v-model="forgotPwUsername"></el-input>
        </el-form-item>
        <el-button
          v-loading="pending"
          native-type="submit"
          type="primary"
          :style="{ width: '70%' }"
        >Email Verification Code</el-button>
      </el-form>
    </div>
    <div v-else>
      <el-form
        class="has-text-left"
        :model="newPasswordForm"
        :rules="rules"
        ref="newPasswordForm"
        @submit.prevent.native="verifyCode"
        label-position="top"
        label-width="140px"
      >
        <el-form-item label="Username" prop="username">
          <el-input type="text" v-model="newPasswordForm.username"></el-input>
        </el-form-item>
        <el-form-item label="Verification Code" prop="code">
          <el-input type="text" v-model="newPasswordForm.code"></el-input>
        </el-form-item>
        <el-form-item label="New Password" prop="password">
          <el-input show-password type="password" v-model="newPasswordForm.password"></el-input>
        </el-form-item>
        <el-button
          v-loading="pending"
          native-type="submit"
          type="primary"
          :style="{ width: '70%' }"
        >Submit</el-button>
      </el-form>
    </div>
    <div class="has-margin-top">
      Remember your password?
      <el-button type="text" @click="showSignIn">Back to Sign In</el-button>
    </div>
  </div>
</template>

<script>
import { AmplifyEventBus } from 'aws-amplify-vue'
import Amplify from 'aws-amplify'

const rules = {
  username: [{ required: true, message: 'Please enter your username', trigger: 'blur' }],
  password: [
    { required: true, type: 'string' },
    { min: 8, message: 'Password must be at least 8 characters.', trigger: 'blur' },
  ],
  code: [
    { required: true, type: 'string', trigger: 'blur', message: 'Please check your email for the verification code' },
  ],
}

export default {
  name: 'ForgotPassword',
  data() {
    return {
      code: '',
      password: '',
      sent: false,
      forgotPwUsername: '',
      newPasswordForm: {
        username: '',
        code: '',
        password: '',
      },
      rules,
      pending: false,
    }
  },
  methods: {
    async sendCode(evt) {
      evt.preventDefault()
      this.pending = true
      try {
        await this.$Amplify.Auth.forgotPassword(this.forgotPwUsername)
        this.newPasswordForm.username = this.forgotPwUsername
        this.sent = true
        this.$message('We sent a password reset code to your email. Check there 📩')
      } catch (e) {
        console.log(e)
        this.$message.error(`Error sending code: ${e.message || e}`)
      } finally {
        this.pending = false
      }
    },
    async verifyCode(evt) {
      evt.preventDefault()
      this.pending = true
      try {
        await this.$Amplify.Auth.forgotPasswordSubmit(
          this.newPasswordForm.username,
          this.newPasswordForm.code,
          this.newPasswordForm.password
        )
        AmplifyEventBus.$emit('authState', 'signIn')
      } catch (e) {
        console.log(e)
        this.$message.error(`Error verifying code: ${e.message || e}`)
      } finally {
        this.pending = false
      }
    },
    showSignIn() {
      AmplifyEventBus.$emit('authState', 'signIn')
    },
  },
}
</script>
