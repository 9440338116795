<template>
  <div>
    <h4 class="is-size-4 has-padding-bottom">Sign in to your Floom account</h4>
    <div class="has-margin-top">
      <el-form @submit.prevent.native="onSubmit" label-position="left" label-width="80px">
        <el-form-item label="Username">
          <el-input type="text" v-model="username"></el-input>
        </el-form-item>
        <el-form-item label="Password">
          <el-input show-password type="password" v-model="password"></el-input>
        </el-form-item>
        <el-button
          v-loading="pending"
          native-type="submit"
          type="primary"
          :style="{ width: '70%' }"
        >Log In</el-button>
      </el-form>
    </div>
    <div class="is-size-7 has-margin-top">
      <router-link :to="{ name: 'privacyPolicy' }" class="has-text-grey-dark">Privacy Policy</router-link>
      {{ ' ' }} |
      <router-link :to="{ name: 'terms' }" class="has-text-grey-dark">Terms of Use</router-link>
    </div>
    <div class="has-margin-top">
      Don't have an account?
      <el-button type="text" @click="showSignUp">Sign Up Here</el-button>
    </div>
    <div class="is-size-7 has-margin-top">
      <el-button type="text" @click="forgotPassword" class="has-text-grey-dark">Forgot Password?</el-button>
    </div>
  </div>
</template>

<script>
import { AmplifyEventBus } from 'aws-amplify-vue'
import Amplify from 'aws-amplify'

export default {
  name: 'SignIn',
  data() {
    return {
      username: '',
      password: '',
      pending: false,
      lastPath: null,
    }
  },
  async created() {
    // return to previous route (prior to 401)
    this.lastPath = '/' // not currently using stored path - localStorage.getItem('lastPath')
  },
  methods: {
    async onSubmit(evt) {
      evt.preventDefault()
      try {
        this.pending = true
        const data = await this.$Amplify.Auth.signIn(this.username, this.password)
        if (data.challengeName === 'SMS_MFA' || data.challengeName === 'SOFTWARE_TOKEN_MFA') {
          AmplifyEventBus.$emit('localUser', data)
          return AmplifyEventBus.$emit('authState', 'confirmSignIn')
        } else if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
          AmplifyEventBus.$emit('localUser', data)
          return AmplifyEventBus.$emit('authState', 'requireNewPassword')
        } else if (data.challengeName === 'MFA_SETUP') {
          AmplifyEventBus.$emit('localUser', data)
          return AmplifyEventBus.$emit('authState', 'setMfa')
        } else if (
          data.challengeName === 'CUSTOM_CHALLENGE' &&
          data.challengeParam &&
          data.challengeParam.trigger === 'true'
        ) {
          AmplifyEventBus.$emit('localUser', data)
          return AmplifyEventBus.$emit('authState', 'customConfirmSignIn')
        } else {
          return AmplifyEventBus.$emit('authState', 'signedIn')
        }
        if (this.$store.state.authStatus === 'success') {
          this.$router.push(this.lastPath)
        } else {
          this.pending = false
        }
      } catch (e) {
        // use elements notification system
        console.log('error', e.message)
        this.$message.error(`Error signing in: ${e.message || e}`)
        this.pending = false
      }
    },
    showSignUp() {
      AmplifyEventBus.$emit('authState', 'signUp')
    },
    forgotPassword() {
      AmplifyEventBus.$emit('authState', 'forgotPassword')
    },
  },
}
</script>
