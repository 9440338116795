<template>
  <div>
    <sign-in v-if="displayMap.showSignIn"></sign-in>
    <sign-up v-if="displayMap.showSignUp" v-bind:signUpConfig="options.signUpConfig"></sign-up>
    <confirm-sign-up
      v-if="displayMap.showConfirmSignUp"
      v-bind:localUsername="options.confirmSignUpConfig.username"
    ></confirm-sign-up>
    <amplify-confirm-sign-in
      v-if="displayMap.showConfirmSignIn"
      v-bind:confirmSignInConfig="options.confirmSignInConfig"
    ></amplify-confirm-sign-in>
    <forgot-password v-if="displayMap.showForgotPassword"></forgot-password>
    <amplify-require-new-password
      v-if="displayMap.requireNewPassword"
      v-bind:requireNewPasswordConfig="options.requireNewPasswordConfig"
    ></amplify-require-new-password>
    <amplify-set-mfa v-if="displayMap.showMfa" v-bind:mfaConfig="options.mfaConfig"></amplify-set-mfa>
    <!-- <br />
    <el-button @click="googleAuth">Sign in with Google</el-button>-->
  </div>
</template>

<script>
import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'
import SignUp from '@/components/Auth/SignUp.vue'
import SignIn from '@/components/Auth/SignIn.vue'
import ConfirmSignUp from '@/components/Auth/ConfirmSignUp.vue'
import ForgotPassword from '@/components/Auth/ForgotPassword.vue'

function GetUser(amplify) {
  return amplify.Auth.currentAuthenticatedUser()
    .then((user) => {
      if (!user) {
        return null
      }
      return user
    })
    .catch((e) => new Error(e))
}

export default {
  name: 'Authenticator',
  props: ['authConfig', 'page'],
  components: {
    SignUp,
    SignIn,
    ConfirmSignUp,
    ForgotPassword,
  },
  data() {
    return {
      user: {
        username: null,
      },
      displayMap: {},
      error: '',
      logger: {},
    }
  },
  computed: {
    options() {
      const defaults = {
        signInConfig: { header: 'Sign In' },
        signUpConfig: { header: 'Sign Up', hiddenDefaults: ['phone_number'] },
        confirmSignUpConfig: { header: 'Check your email for a verification code' },
        confirmSignInConfig: {},
        mfaConfig: {},
        requireNewPasswordConfig: {},
      }
      return Object.assign(defaults, this.authConfig || {})
    },
  },
  async mounted() {
    this.logger = new this.$Amplify.Logger(this.$options.name)
    AmplifyEventBus.$on('localUser', (user) => {
      this.user = user
      this.options.signInConfig.username = this.user.username
      this.options.confirmSignInConfig.user = this.user
      this.options.confirmSignUpConfig.username = this.user.username
      this.options.requireNewPasswordConfig.user = this.user
    })
    AmplifyEventBus.$on('authState', (data) => {
      this.displayMap = this.updateDisplayMap(data)
    })
    GetUser(this.$Amplify)
      .then((val) => {
        if (val instanceof Error) {
          switch (this.page) {
            case 'sign-up':
              this.displayMap = this.updateDisplayMap('signUp')
              break
            case 'sign-in':
              this.displayMap = this.updateDisplayMap('signedOut')
              break
            case 'forgot-password':
              this.displayMap = this.updateDisplayMap('forgotPassword')
              break
            case 'confirm-sign-up':
              this.displayMap = this.updateDisplayMap('confirmSignUp')
              break
            default:
              this.displayMap = this.updateDisplayMap('signedOut')
          }
          return
        }
        this.user = val
        switch (this.page) {
          case 'confirm-sign-up':
            this.displayMap = this.updateDisplayMap('confirmSignUp')
            break
          case 'confirm-sign-in':
            this.displayMap = this.updateDisplayMap('confirmSignIn')
            break
          case 'forgot-password':
            this.displayMap = this.updateDisplayMap('confirmSignIn')
            break
          case 'require-new-password':
            this.displayMap = this.updateDisplayMap('requireNewPassword')
            break
          case 'set-mfa':
            this.displayMap = this.updateDisplayMap('setMfa')
            break
          default:
            this.displayMap = this.updateDisplayMap('signedIn')
            break
        }
      })
      .catch((e) => this.setError(e))
  },
  methods: {
    googleAuth() {
      Auth.federatedSignIn({ provider: 'Google' })
    },
    updateDisplayMap(state) {
      console.log({ state })
      this.$emit('updatePage', state)
      return {
        showSignIn: state === 'signedOut' || state === 'signIn',
        showSignUp: state === 'signUp',
        showConfirmSignUp: state === 'confirmSignUp',
        showConfirmSignIn: state === 'confirmSignIn',
        showForgotPassword: state === 'forgotPassword',
        showSignOut: state === 'signedIn',
        showMfa: state === 'setMfa',
        requireNewPassword: state === 'requireNewPassword',
      }
    },
    setError(e) {
      this.error = this.$Amplify.I18n.get(e.message || e)
      this.logger.error(this.error)
    },
  },
}
</script>
