<template>
  <div>
    <h4
      class="is-size-4 has-padding-bottom has-normal-word-break"
    >The future of software products lives here.</h4>
    <div class="has-margin-top">
      <el-form
        :model="signUpForm"
        :rules="rules"
        ref="signUpForm"
        @submit.prevent.native="onSubmit('signUpForm')"
        label-position="left"
        label-width="100px"
      >
        <el-form-item label="Username" prop="username">
          <el-input type="text" v-model="signUpForm.username"></el-input>
        </el-form-item>
        <el-form-item label="Email" prop="email">
          <el-input type="text" v-model="signUpForm.email"></el-input>
        </el-form-item>
        <el-form-item label="Password" prop="password">
          <el-input show-password type="password" v-model="signUpForm.password"></el-input>
        </el-form-item>
        <el-button
          v-loading="pending"
          native-type="submit"
          type="primary"
          :style="{ width: '70%' }"
        >Sign Up</el-button>
      </el-form>
    </div>
    <div v-if="error">{{ error }}</div>
    <div class="is-size-7 has-margin-top">
      <router-link :to="{ name: 'privacyPolicy' }" class="has-text-grey-dark">Privacy Policy</router-link>
      {{ ' ' }} |
      <router-link :to="{ name: 'terms' }" class="has-text-grey-dark">Terms of Use</router-link>
    </div>
    <div class="has-margin-top">
      Already have an account?
      <el-button @click="showSignIn" type="text">Sign in here</el-button>
    </div>
  </div>
</template>
<script>
import { AmplifyEventBus } from 'aws-amplify-vue'

const rules = {
  username: [
    { required: true, message: 'Username is required.', trigger: 'blur' },
    { min: 4, max: 20, message: 'Username must be 4-20 characters.', trigger: 'blur' },
    { pattern: /^[a-zA-Z0-9]*$/, message: 'Usernames can only contain letters and numbers.', trigger: 'blur' },
  ],
  email: [{ required: true, type: 'email', message: 'Email is required', trigger: 'blur' }],
  password: [
    { required: true, type: 'string' },
    { min: 8, message: 'Password must be at least 8 characters.', trigger: 'blur' },
  ],
}

export default {
  name: 'SignUp',
  data() {
    return {
      signUpForm: {
        username: '',
        email: '',
        password: '',
      },
      rules,
      pending: false,
      error: '',
    }
  },
  methods: {
    async onSubmit(signUpForm) {
      this.pending = true
      try {
        this.$refs[signUpForm].validate(async (valid) => {
          if (valid) {
            console.log(this.signUpForm)
            try {
              const signUpData = await this.$Amplify.Auth.signUp({
                username: this.signUpForm.username,
                password: this.signUpForm.password,
                attributes: {
                  email: this.signUpForm.email,
                },
              })
              AmplifyEventBus.$emit('localUser', signUpData.user)
              console.log(signUpData)
              if (!signUpData.userConfirmed) {
                console.log('emitting confirmsignup')
                AmplifyEventBus.$emit('authState', 'confirmSignUp')
              } else {
                AmplifyEventBus.$emit('authState', 'signIn')
              }
            } catch (e) {
              console.log(e)
              this.$message.error(`Error signing up: ${e.message || e}`)
            }
          } else {
            console.log('Error signing up!')
            this.$message.error(`Error signing up`)
          }
          return valid
        })
      } catch (e) {
        console.log('Error here')
        console.log(e)
        this.$message.error(`Error signing up: ${e}`)
      } finally {
        this.pending = false
      }
    },
    showSignIn() {
      AmplifyEventBus.$emit('authState', 'signIn')
    },
  },
}
</script>
